import { __extends } from "tslib";
import Attribute from './base';
var Opacity = /** @class */ (function (_super) {
    __extends(Opacity, _super);
    function Opacity(cfg) {
        var _this = _super.call(this, cfg) || this;
        _this.type = 'opacity';
        _this.names = ['opacity'];
        return _this;
    }
    return Opacity;
}(Attribute));
export default Opacity;
