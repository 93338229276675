import { __extends } from "tslib";
import Attribute from './base';
var Size = /** @class */ (function (_super) {
    __extends(Size, _super);
    function Size(cfg) {
        var _this = _super.call(this, cfg) || this;
        _this.type = 'size';
        _this.names = ['size'];
        return _this;
    }
    return Size;
}(Attribute));
export default Size;
