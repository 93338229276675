import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, DatePicker, Upload } from 'antd';
import store from "../store/store.js";
import { FormOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment';
const { TextArea } = Input;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: {
                id: props.id ? props.id : 0,
                type: null,
            },
            visible: false,
            loading: false
        };
    }
    componentDidMount() {
        let that = this
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.condition, { [name]: value })
        this.setState({
            condition: data
        })
    }
    onckboxChange(name, value) {
        let that = this
        let data = Object.assign({}, this.state.condition, { [name]: value })
        that.setState({
            condition: data
        })
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.condition, { [name]: dateString })
        this.setState({
            condition: data
        })
    }
    arrqx_react() {
        return this.state.qx_react.map((item, index) =>
            <Col span={6}>
                <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
            </Col>
        )
    }
    arrqx(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Col span={4}>
                    <Checkbox value={item.id} key={index}>{item.name}</Checkbox>
                </Col>
            )
        }
    }
    arrradio(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Radio value={item.id} key={index}>{item.name}</Radio>
            )
        }
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    mdchange() {
        let that = this
        let visible = !that.state.visible
        that.setState({
            visible
        })
        if (visible) {
            fetch('/Clxx/view', {
                method: "POST",
                body: JSON.stringify({
                    id: this.state.condition.id
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (this.state.condition.id) {
                        that.setState({
                            condition: data.data,
                        })
                    }
                    that.setState({
                        sblx_react: data.sblx_react,
                        sj_react: data.sj_react,
                        status_react: data.status_react,
                    })
                })
        } else {
            that.setState({
                condition: {
                    id: this.props.id,
                },
                loading: false
            })
        }
    }
    sub() {
        let that = this
        that.setState({
            loading: true
        })
        fetch('/Clxx/doview', {
            method: "POST",
            body: JSON.stringify(that.state.condition)
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == 1) {
                    message.success(data.msg)
                    this.props.refresh()
                    this.mdchange()
                } else {
                    message.error(data.msg)
                    that.setState({
                        loading: false
                    })
                }
            })
    }
    // 上传图片
    picChange(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response)
            if (info.file.response.status == 0) {
                message.error(info.file.response.msg)
                return
            }

            // var fjlist = this.state.condition.fjlist
            // fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: info.file.response.id, [name + '_url']: info.file.response.url, [name + '_name']: info.file.response.name })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    // 上传图片
    picChange2(name, info) {
        // console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ loading2: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // console.log(info.file.response)

            var fjlist = this.state.condition[name]
            if (!fjlist) {
                fjlist = []
            }
            fjlist = fjlist.concat(info.file.response)
            let data = Object.assign({}, this.state.condition, { [name]: fjlist })
            this.setState({
                condition: data,
                loading2: false,
            })
        }
    };
    delpic(index, name, e) {
        var fjlist = this.state.condition[name]
        fjlist.splice(index, 1)
        let data = Object.assign({}, this.state.condition, { [name]: fjlist })
        this.setState({
            condition: data,
        })
    }
    render() {
        return (
            <>
                {this.state.condition.id ? <a href='javascript:void(0)' onClick={this.mdchange.bind(this)} >{store.getState().data.info.qx17 ? (store.getState().data.info.qx17 == 1 ? '编辑' : '查看') : '查看'}</a> : <Button type="primary" className='yellow' onClick={this.mdchange.bind(this)}>添加</Button>}
                <Modal maskClosable={false} title="编辑" width={800} open={this.state.visible} onCancel={this.mdchange.bind(this)} footer={null}>
                    <Form
                        layout="vertical"
                        // labelCol={{ span: 2 }}
                        wrapperCol={{ span: 24 }}
                        disabled={store.getState().data.info.qx17 ? (store.getState().data.info.qx17 == 1 ? false : true) : true}
                    >
                        <Row gutter={[20, 0]} justify="start" align="top">
                            <Col span={12}>
                                <Form.Item
                                    label="车辆编号"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.clbh ? this.state.condition.clbh : null} onChange={this.onChange.bind(this, 'clbh')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="车牌"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.cp ? this.state.condition.cp : null} onChange={this.onChange.bind(this, 'cp')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="类型"
                                    required={true}
                                >
                                    <Select placeholder="请选择" value={this.state.condition.sblx ? this.state.condition.sblx : null} allowClear onChange={this.onSelChange.bind(this, 'sblx')}>
                                        {this.arrsel(this.state.sblx_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="规格"
                                    required={true}
                                >
                                    <Input placeholder="请输入" value={this.state.condition.gg ? this.state.condition.gg : null} onChange={this.onChange.bind(this, 'gg')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="注册日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'zcrq')} value={this.state.condition.zcrq ? moment(this.state.condition.zcrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="产权所有人"
                                >
                                    <Input placeholder="请输入" value={this.state.condition.cqsyr ? this.state.condition.cqsyr : null} onChange={this.onChange.bind(this, 'cqsyr')}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="保险日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'bxrq')} value={this.state.condition.bxrq ? moment(this.state.condition.bxrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="验车日期"
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请输入" onChange={this.onTimeChange.bind(this, 'ycrq')} value={this.state.condition.ycrq ? moment(this.state.condition.ycrq) : null} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="司机一"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sj1 ? this.state.condition.sj1 : null} onChange={this.onSelChange.bind(this, 'sj1')} allowClear>
                                        {this.arrsel(this.state.sj_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="司机二"
                                >
                                    <Select placeholder="请选择" showSearch optionFilterProp="children" value={this.state.condition.sj2 ? this.state.condition.sj2 : null} onChange={this.onSelChange.bind(this, 'sj2')} allowClear>
                                        {this.arrsel(this.state.sj_react)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="保险附件"
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'bxfj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.bxfj ? this.state.condition.bxfj.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'bxfj')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="行驶证附件"
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'xszfj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.xszfj ? this.state.condition.xszfj.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'xszfj')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item
                                    label="车辆资料附件"
                                >
                                    <Row justify="space-between">
                                        <Space align="center">
                                            <Upload
                                                showUploadList={false}
                                                action="/Common/uploadfile"
                                                onChange={this.picChange2.bind(this, 'clzlfj')}
                                                multiple={true}
                                            >
                                                <Button icon={<UploadOutlined />}>上传</Button>
                                            </Upload>
                                            <Space wrap>
                                                {this.state.condition.clzlfj ? this.state.condition.clzlfj.map((item, index) =>
                                                    <div>
                                                        <a href={item.url} target="_blank">{item.name} </a> <DeleteOutlined className="hongse" onClick={this.delpic.bind(this, index, 'clzlfj')} />
                                                    </div>) : null}

                                            </Space>
                                        </Space>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="备注"
                                >
                                    <TextArea placeholder="请输入" rows={6} value={this.state.condition.beizhu ? this.state.condition.beizhu : null} onChange={this.onChange.bind(this, 'beizhu')}  />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="状态"
                                >
                                    <Radio.Group onChange={this.onChange.bind(this, 'status')} value={this.state.condition.status ? this.state.condition.status : null} >
                                        {this.arrradio(this.state.status_react)}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        {store.getState().data.info.qx17 ? (store.getState().data.info.qx17 == 1 ?
                            <Row justify="center">
                                <Button className='blue' style={{ width: 140 }} loading={this.state.loading} onClick={this.sub.bind(this)}>确认</Button>
                            </Row>
                            : null) : null}
                    </Form>
                </Modal>
            </>
        )
    }
}
